import React, { useEffect } from "react"
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Layout from "../components/layout/layout"
import { Helmet } from "react-helmet"

const SuccessMessage = styled.div`
    min-height: calc(100vh - 139px);
    max-width: 820px;
    margin: auto;
    padding: 2.5em 0;
    width: ${props => props.messageWidth}%;
    
    h1 {
        font-size: var(--type-xxxl);
        text-transform: none;
        margin-bottom: 0;
        color: ${props => props.titleColor};
    }

    p {
        color: ${props => props.bodyColor};
    }

    @media only screen and (min-width: 900px) {
        min-height: calc(100vh - 179px)
    }
`;

SuccessMessage.propTypes = {
    titleColor: PropTypes.string,
    bodyColor: PropTypes.string,
    messageWidth: PropTypes.number,
};


SuccessMessage.defaultProps = {
    titleColor: 'var(--accent-turquoise-500)',
    bodyColor: 'var(--neutral-900)',
    messageWidth: 100,
};


const Success = () => {
    useEffect(() => {
        setTimeout(function() {
            document.location = '/';
        }, 5000);
    }, []);


    return (
        <Layout>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Thank You | Caplin Solar</title>
                <html lang="en"></html>
            </Helmet>
            <SuccessMessage messageWidth={80}>
                <h1>Thank you</h1>
                <p>Your message has been successfully submitted. We'll get back to you as soon as we can.</p>
            </SuccessMessage>
        </Layout>
    )
}

export default Success;




